import app from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/storage'

 
  
const firebaseConfig = {
  apiKey: "AIzaSyAOwLWRlBri5hOlvGec4BSlFJ8oYZIU-BY",
  authDomain: "orchid11medspa-6e432.firebaseapp.com",
  databaseURL: "https://orchid11medspa-6e432-default-rtdb.firebaseio.com",
  projectId: "orchid11medspa-6e432",
  storageBucket: "orchid11medspa-6e432.appspot.com",
  messagingSenderId: "711778663448",
  appId: "1:711778663448:web:c5455bbf732bbdcdb4176d",
  measurementId: "G-EJ5VSCZD9F"
};
  
class Firebase{

    constructor(){
      if (!app.apps.length) {
        app.initializeApp(firebaseConfig);
     }else {
        app.app(); // if already initialized, use that one
     }
      
      //  app.initializeApp(firebaseConfig)
        this.auth = app.auth()
        this.db = app.database()
        this.storageBucket = app.storage() 

    }
  
    /** AUTH API */
    doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);
    doSignOut = () => this.auth.signOut();
    
    /** REALTIME DATABASE API */

    currentUser = () => this.db.ref('appointments')

    freeAppointments = () => this.db.ref('appointments/freeAppointments')

    bookedAppointments = () => this.db.ref('appointments/bookedAppointments')

 
     /**
      * 
      * @param { {
         'title' : tittle,
            'allDay' : false,
            'start' : new Date(selectedDate).toString(),
            'end': new Date(selectedEndDate).toString(),
            'epoch' : new Date(selectedDate).getTime().toString(),
            'id': uuid(),
            extra : {
                employee: 'any'
            }
      */

     formatterHelperFunction = (year, month) =>{
       if ( month < 10 ) {
         const newYear = year*10
         return `${newYear}${month}`
       }
       return `${year}${month}`
      }

    writeEventData = (events) => {
      var updates = {};

      for (var event of events){
        const thisDate = new Date(event.start)
        const month = thisDate.getMonth().toString()
        const year = thisDate.getFullYear().toString()
        const value = this.formatterHelperFunction(year,month)
        const newPostKey = this.db.ref(`appointments/freeAppointments/${value}`)
        .push().key
         updates[`appointments/freeAppointments/${value}/` + newPostKey] = {...event, eventKey: `appointments/freeAppointments/${value}/${newPostKey}`};
      }
      return this.db.ref().update(updates)
    }

    //  All this method needes is an array of object containing eventkey
    deleteEventsForSure = (events) => {
      var updates= {}
      for (var i = 0; i<events.length;i++){
         updates[events[i].eventKey] = null
      }
       return this.db.ref().update(updates);

    }

    deleteBookedAppointment = (userdata) =>{
      var updates= {}
      updates[userdata.eventKey] = null
      updates[userdata.bookedAppointmentKey] = null
      return this.db.ref().update(updates);
    }




}


export default Firebase