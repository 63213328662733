//  ACTION  TYPES

export const ADD_EVENT = 'ADD_EVENT'
export const REMOVE_EVENT = 'REMOVE_EVENT'
export const EVENT_SENT = 'EVENT_SENT'
export const EVENT_SUCCESS = 'EVENT_SUCCESS'
export const EVENT_REJECTED = 'EVENT_REJECTED'



// CALENDER PAGE
export const ADD_SERVICE_FOR_APPOINTMENT = 'ADD_SERVICE_FOR_APPOINTMENT'
export const REMOVE_SERVICE_FOR_APPOINTMENT = 'REMOVE_SERVICE_FOR_APPOINTMENT'
export const RESET_APPOINTMENT = 'RESET_APPOINTMENT'



// CONSFIRMARTION PAGE
export const ADD_SERVICE = 'ADD_SERVICE'
export const REMOVE_SERVICE = 'EVENT_REJECTED'




// ACTION CREATORS
// they just return an object with type and payload 

export const addService = update => ({
    type: ADD_SERVICE,
    payload: update
})

export const addServiceForAppointment = update => ({
    type: ADD_SERVICE_FOR_APPOINTMENT,
    payload: update
})

export const removeServiceForAppointment = update => ({
    type: REMOVE_SERVICE_FOR_APPOINTMENT,
    payload: update
})

export const resetAppointment = () => ({
    type: RESET_APPOINTMENT,
})

export const removeService = update => ({
    type: REMOVE_SERVICE,
    payload: update
})

export const addEvent = update => ({
    type: ADD_EVENT,
    payload : update
})

export const removeEvent = update => ({
    type : REMOVE_EVENT,
    payload : update
})






// ASYNC ACTION CREATORS

// const sendAddEvent = (somedata) => dispatch => {
//     dispatch({type : EVENT_SENT})

//     fetch()
//         .then((res)=>{
//             dispatch({type : EVENT_SUCCESS})
//         })
//         .catch((err)=>{
//             dispatch({type : EVENT_REJECTED})

//         })

// }