import React from 'react'
import {withRouter} from 'react-router-dom'
 

function Service(props) {
    return (
        <div style={{
            padding: '1rem',
            borderRadius: '10px',
            boxShadow: 'rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px',
            margin: '1rem 0'
        }}>
            <div style ={{
                 display: 'flex',
                 flexDirection: 'row',
                 justifyContent: 'space-between',
                 paddingBottom: '1rem'
            }}>
                <div>
                    {props.service.name}
                </div>
                
                <div style={{color: '#85bb65'}}>
                    {props.service.price}
                </div>
            </div>
            <div>
                {props.service.description}
            </div>
            
        </div>
    )
}


 function ServiceDetailView(props) {

     const handleBookedAppointmentDelete = () => {
          props.handleBookedAppointmentDelete(props.appointment.userdata)
     }
    const services = props.appointment.services.map((service, key)=>( <Service key={key} service={service} />) )

    return (
        <div style={{
                textAlign: 'left',
                padding: '1rem',
                flex: '0 1 500px',
                alignItems: 'center',
                fontSize: '1.2rem',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                borderRadius: '10px',
                boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px' 
              }} >
            <div>
                Email : <span style={{color: '#266bcd'}}>
                        {` ${props.appointment.userdata.email}`}
                    </span> 
            </div>
            <div>
                Name : <span style={{color: '#266bcd'}}>
                        {` ${props.appointment.userdata.name || "N.A"}`}
                    </span> 
            </div>
            <div>
                Number : <span style={{color: '#266bcd'}}>
                        {` ${props.appointment.userdata.number || "N.A."}`}
                    </span> 
            </div>
             <div style={{padding: '0.5rem 0'}}>
                Appointment Start Time : {` ${props.appointment.userdata.start.substring(0,24)}`}
            </div>

            <div style={{padding: '0.5rem 0'}}>
                Appointment End Time : {` ${props.appointment.userdata.end && props.appointment.userdata.end.length > 23 ? props.appointment.userdata.end.substring(0,24) : ''}`}
            </div>
            <div style={{padding: '0.5rem 0'}}>
                Employee Name: {` ${props.appointment.userdata.employee ? props.appointment.userdata.employee : ''}`}
            </div>

 
            <div style={{
                padding: '1rem 0'
            }}>
                {services}
            </div>
            <div>
            <button onClick={handleBookedAppointmentDelete} style={{
                    backgroundColor: 'red',
                    color: 'white',
                    border: '2px solid #4CAF50',
                    padding: '16px 32px',
                    cursor: 'pointer',
                    borderRadius: '10px' ,
                    marginLeft: '10px',
                    textAlign: 'center'
                }}>
                    DELETE
                </button>

            </div>
            
        </div>
    )
}

 function DetailView(props) {
    const handleBookedAppointmentDelete = (userdata) => {
         props.handleBookedAppointmentDelete(userdata).then(()=>{
             if(props.appointments.length < 2){
                props.history.push('/bookedappointments')
             }

         })
    }
    let appointments; 
    if (props.appointments){
         appointments = props.appointments.map((appointment,index)=>(<ServiceDetailView handleBookedAppointmentDelete={handleBookedAppointmentDelete} key={index} appointment={appointment} />))
    }else{
        props.history.push('/bookedappointments')
    }

    const year = props.id.substring(0,4)

    return (
        <div >
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                padding: '2rem'
            }}>
            <button onClick={()=> props.history.push('/bookedappointments/')} style={{
                    backgroundColor: 'white',
                    color: 'black',
                    border: '2px solid #4CAF50',
                    padding: '16px 32px',
                    cursor: 'pointer',
                    borderRadius: '10px' ,
                    marginLeft: '10px',
                    textAlign: 'left'
                }}>
                    BACK
                </button>
            </div>
            <h3 style={{textAlign: 'center'}}>
              {new Date(year,props.id.substring(4)).toLocaleString('default', { month: 'long' })}, {year}
            </h3>
           <h5 style={{textAlign: 'center'}}>
            Total {props.appointments && props.appointments.length } Appointments
            </h5>
            <div style={{
                display: 'flex',
                gap: '50px',
                flexWrap: 'wrap',
                border: '1px solid black',
                padding: '2rem'
            }}>
                {appointments}
            </div>

        </div>
    )
}
export default withRouter(DetailView)