import React from 'react'

export default function Footer() {
    return (
        <div
        style ={{ 
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            padding: '1rem',
            margin: '0',
            backgroundColor: '#5ADEFF',
            color: 'white',
            flexWrap: 'wrap',
            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
        }} >
            Copyright {new Date().getFullYear()}
        </div>
    )
}
