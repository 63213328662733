import React from 'react'
import DashboardView from "./DashboardView";


export default function Masterview(props) {
  
    const displayDashboardData = Object.keys(props.bookedAppointments).map((key)=>{
        const year = key.substring(0,4)
        var month = key.substring(4)
        month = new Date(year,month).toLocaleString('default', { month: 'long' })
        const length = props.bookedAppointments[key].length

        return (
            <DashboardView

                key={key}
                yearmonth={key}
                title= {`${month}, ${year}`}
                length= {length} 
            />
        )

    })
    return (
        <div>
              <h2 style={{ textAlign: 'center'}}>
                Booked Appointments
            </h2>
            <div style={{
                display: 'flex',
                gap: '50px',
                flexWrap: 'wrap',
                padding: '2rem'
            }}>
                {displayDashboardData}
            </div>

            
        </div>
    )
}
