import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import React , {useState , useEffect} from 'react'
import './react-big-calendar.css'
import uuid from 'uuid/v4'
import { withFirebase } from "../app_state/FirebaseContext";
 
const localizer = momentLocalizer(moment)
 

function DeleteEventsDashboard (props) {

  const eve = props.events.map((event,index)=>(<DeleteEvent key={index} event={event}/>))
if (props.events.length > 0){
  return (
    <div style={{padding: '2rem 0'}}>
      <h3>
         Delete  Events Dashboard
      </h3>

    <div  style={{
      textAlign: 'left',
      padding: '1rem',
      fontSize: '1.2rem',
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',

      justifyContent: 'space-between',
      borderRadius: '10px',
      boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px' 
    }}>
      {eve}
      </div>
      <div>

      <button style={{
                    backgroundColor: 'white',
                    color: 'black',
                    border: '2px solid #4CAF50',
                    padding: '16px 32px',
                    cursor: 'pointer',
                    borderRadius: '10px' ,
                    marginLeft: '10px',
                    textAlign: 'left'
                }} onClick={props.deleteEventsForSure} > DELETE SELECTED EVENTS </button>
        <button style={{
                    backgroundColor: 'white',
                    color: 'black',
                    border: '2px solid #4CAF50',
                    padding: '16px 32px',
                    cursor: 'pointer',
                    borderRadius: '10px' ,
                    marginLeft: '10px',
                    textAlign: 'left'
                }} onClick={props.clearDeleteEventsArray} > CLEAR SELECTION </button>
      
    </div>
    </div>

  )
              } else {
                return (<div></div>)
              }
}

 
  function DeleteEvent(props) {
  return (
    <div  style={{
      textAlign: 'left',
      padding: '1rem',
       alignItems: 'center',
      fontSize: '1.2rem',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '10px',
      boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px' 
    }}>
       
      <div>
      <h5>
        Start Time: 
      </h5> {props.event.start.toString().substr(0,24)}
      </div>
      <div>
      <h5>
        End Time: 
      </h5>
      {props.event.end.toString().substr(0,24)}
      </div>
    </div>
  )
}


/**
 * Calender data flow 
 * > data comes from firebase hook
 * > goes to to a method name events
 * > goes to the state of the component
 * > optional event filter
 * 
 */

 function Calender(props) {
  const today = new Date();

  const [monthFetchingOffset, setMonthFetchingOffset ] = useState(2)

  const [deleteModeBool, setDeletemod] = useState(true)
  const [deleteEventsArray, setDeleteEvents] = useState([])

  const addEventinAnArray = (oldarray , event) =>{
    if (event.extra.status && event.extra.status === "booked")      //  event is booked cannot be deleted
      return oldarray


    for (var i = 0 ; i < oldarray.length; i++){
      if(oldarray[i].eventKey === event.eventKey){
        window.alert('Already Selected')
        return oldarray; // already in an array
      }
    }
    return [...oldarray,event];
  } 

  const [eventsToDisplay, handleeventsToDisplay] = useState( [{
    'title': 'All Day Event very long title',
    'allDay': true,
    'start': new Date(2020, 9, 7),
    'end': new Date(2020, 9, 8),
    id : uuid()
  }] );

  // 'allDay' : false,
  //           'start' : new Date(selectedDate).toString(),
  //           'end': new Date(selectedEndDate).toString(),
  //           'epoch' : new Date(selectedDate).getTime().toString(),
  //           'id': uuid(),
  //           extra : {
  //               employee: 'any'
  //           }
  
  const events = (arrayOfEvents) => {
    if (arrayOfEvents){
      const newEvents = arrayOfEvents.map((event)=> ({
                    ...event,
                    start: new Date( event.start),
                    end : new Date (event.end) ,
                  }))
      return newEvents
    } else return []
  }

  // this.props.firebase.appointments

  //  this.props.firebase.users().on('value', snapshot => {
  //   const usersObject = snapshot.val();
  //   const usersList = Object.keys(usersObject).map(key => ({
  //   ...usersObject[key],
  //   uid: key,
  //   }));

  useEffect(() => {
    props.firebase.freeAppointments()
    .orderByKey()
    .startAt(props.firebase.formatterHelperFunction( today.getFullYear() , today.getMonth() ) )
    .limitToFirst(monthFetchingOffset)
    .on('value', snapshot => {
      console.log('coming from firebase')
       const val = snapshot.val()
      if (val) {
        const newevents = []
       Object.keys(val).map((monthlykey)=>{
          const thisMonthevents = val[monthlykey]
          Object.keys(thisMonthevents).map((key)=>{
            newevents.push({ ...thisMonthevents[key] })
          })

       })
       // setter method is hanfleeventstodisplay and object formatting method is events
       handleeventsToDisplay( events(newevents) ) 
      }
      console.log(today, monthFetchingOffset)

     })
      return () => {
          props.firebase.freeAppointments().off()
        }
  }, [monthFetchingOffset])

    const handleUserTimeSlotClick = (timeSlotObject ) => {
      if(deleteModeBool){
        setDeleteEvents( addEventinAnArray( deleteEventsArray,timeSlotObject))
      }
    } 

   // eventPropGetter={() => ( {style : { backgroundColor : "red" } } )}
  //  end: Mon Dec 07 2020 10:00:36 GMT-0800 (Pacific Standard Time) {}
  //  epoch: 1607356836965
  //  eventkey: "202011/-MNqj7EMfsaIMxx0sWGt"
  //  extra: {employee: "any"}
  //  id: "e6183c67-96a4-48ff-b29b-b3ab92a195ed"
  //  start: Mon Dec 07 2020 08:00:36 GMT-0800 (Pacific Standard Time) {}
  //  title: "Appointment Available"
   const handleEventPropGetter = (eventObject)=>{

    if(eventObject.extra.status){
      return {style: {backgroundColor: 'red'}}
    }

    if(eventObject.extra.employeeColor){
      return {style: {backgroundColor: eventObject.extra.employeeColor}}
    }

   
  }
  /*
  This function validates the offset and if offset is more than the current offset is replaced with the new offset
  */
  const handleOffsetReview = (offset) =>{
    offset = offset+1
     if(offset < 0){
        return
    }
    
    else if (offset < monthFetchingOffset)
      return
     setMonthFetchingOffset(offset)
  }

  const handleOnNavigate = (dateRecieved)=>{
   const v = new Date (dateRecieved)
   var offset = 0
   const tempMonth = v.getMonth() + 1
   const tempYear = v.getFullYear()
   const currentMonth = today.getMonth() + 1
   const currentYear = today.getFullYear() 
   if(currentYear === tempYear){
      offset = tempMonth - currentMonth
      if(offset>0){
        handleOffsetReview(offset)
        return
      }
      else return
   }else {
     if( tempYear > currentYear){
       offset = (12*(tempYear-currentYear)) - currentMonth + tempMonth
       handleOffsetReview(offset)
     }
     else return 
   }

  } 

  const deleteEventsForSure = () => {
    props.firebase.deleteEventsForSure(deleteEventsArray).then(()=>{
      setDeleteEvents([])
      window.alert('Delete Confirmed')
    })
    .catch(err=>{
       window.alert('Something went wrong, let Paramveer know about this. ')
   })
  }

  const clearDeleteEventsArray = () => {
    setDeleteEvents([])
  }

   
 
    
  return (
        <div className='calendar-container' >
         
          <DeleteEventsDashboard clearDeleteEventsArray={clearDeleteEventsArray} deleteEventsForSure={deleteEventsForSure} events={deleteEventsArray} />
          <Calendar
            localizer={localizer}
            startAccessor="start"
            views={['month','week' , 'day' ]}
            onNavigate={handleOnNavigate}
            endAccessor="end"
            eventPropGetter={handleEventPropGetter}
            events={eventsToDisplay}
            defaultView='week'
            step = {30}
            min={new Date(today.getFullYear(),today.getMonth(),today.getDate(), 8 ) }
            max={new Date(today.getFullYear(), today.getMonth(), today.getDate(), 17) }
            onSelectEvent = {handleUserTimeSlotClick}
            style={{ height: 1000 }}
          />
            
        </div>
  )
}

export default withFirebase( Calender )
