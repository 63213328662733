import React, { useState, useEffect } from "react";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import Checkbox from "@material-ui/core/Checkbox";
import { Button } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import uuid from "uuid/v4";
import EmployeeCard from "./EmployeeCard";
import { withFirebase } from "../app_state/FirebaseContext";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const employees = [
  {
    name: "Bonnie",
    color: "#3174ad",
    id: uuid(),
  },
  {
    name: "Harpreet",
    color: "#F4A4AC",
    id: uuid(),
  },
  {
    name: "Kiran",
    color: "#CAADFF",
    id: uuid(),
  },
];

function EventsAddingForm(props) {
  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const classes = useStyles();
  const [selectedDate, handleDateChange] = useState(new Date());
  const [selectedEndDate, handleEndDateChange] = useState(new Date());
  const [activeEmployee, handleActiveEmployee] = useState({});
  const [checked, setChecked] = React.useState(false);

  const [
    checkIfDisableEveryDayInWeekCheckbox,
    setCheckIfDisableEveryDayInWeekCheckbox,
  ] = useState(false);

  const [state, setState] = React.useState({
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
  });

  useEffect(() => {
    if (
      state.Monday ||
      state.Saturday ||
      state.Thursday ||
      state.Wednesday ||
      state.Tuesday ||
      state.Friday
    ) {
      setCheckIfDisableEveryDayInWeekCheckbox(false);
    } else setCheckIfDisableEveryDayInWeekCheckbox(true);
  }, [state]);

  const handleWeekDayChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const minutes = [30, 60, 90, 120, 150];

  const handleEndHelper = (val) => {
    // val is in minutes, so * 60 for seconds and * 1000 for milli
    handleEndDateChange(new Date(selectedDate.getTime() + val * 60 * 1000));
  };

  const emoployeesCards = employees.map((emp) => (
    <EmployeeCard
      handleActiveEmployee={handleActiveEmployee}
      activeEmployee={activeEmployee}
      employee={emp}
      key={emp.color}
    />
  ));
  const [noOfWeeks, setNoOfWeeks] = React.useState(0);
  const [tittle, handleTitleChange] = useState("Appointment Available");

  const weekDaysSelector = days.map((day, index) => (
    <FormControlLabel
      key={index}
      control={
        <Checkbox
          checked={state.day}
          color='primary'
          onChange={handleWeekDayChange}
          name={day}
        />
      }
      label={day}
    />
  ));

  const formControlButtons = () => {
    let temp = [];

    for (let i = 0; i < 53; i++) {
      temp.push(
        <MenuItem key={uuid()} value={i}>
          {i}
        </MenuItem>
      );
    }
    return temp;
  };

  // this function handles adding an event for the day, for the week and for the next 52 weeks
  const addThisEvent = () => {
    if (new Date(selectedDate).getDay() === 0) {
      window.alert("Store is closed on sunday, select some other day");
      return;
    }
    if (activeEmployee.color === undefined) {
      window.alert("Select an employee please");
      return;
    }

    let event = [
      {
        title: tittle,
        allDay: false,
        start: new Date(selectedDate).toString(),
        end: new Date(selectedEndDate).toString(),
        id: uuid(),
        extra: {
          employee: activeEmployee.name,
          employeeColor: activeEmployee.color,
        },
      },
    ];

    const ONE_DAY_TIME = 86400000;
    const startNew = selectedDate.getTime();
    const endNew = selectedEndDate.getTime();

    if (checkIfDisableEveryDayInWeekCheckbox === false && noOfWeeks > 0) {
    }

    if (checkIfDisableEveryDayInWeekCheckbox === false && noOfWeeks === 0) {
      console.log(event, "event");

      const days = Object.keys(state);
      const todayIndex = selectedDate.getDay() - 1;
      for (let index = todayIndex; index < days.length; index++) {
        const day = days[index];
        console.log("day", day);
        if (
          state[day] === false ||
          new Date(selectedDate).getDay() - 1 === index
        ) {
          // user didnt select this day
          continue;
        }
        const NEW_START = (index - todayIndex) * ONE_DAY_TIME + startNew;
        const NEW_END = (index - todayIndex) * ONE_DAY_TIME + endNew;
        console.log(
          " adding event for ",
          new Date(NEW_START).toString(),
          "todayindex",
          todayIndex,
          "index ",
          index
        );
        const eventAdd = {
          title: tittle,
          allDay: false,
          start: new Date(NEW_START).toString(),
          end: new Date(NEW_END).toString(),
          id: uuid(),
          extra: {
            employee: activeEmployee.name,
            employeeColor: activeEmployee.color,
          },
        };
        event.push(eventAdd);
      }
    }

    // Adding one event one day per week every week
    if (!checked && noOfWeeks > 0) {
      for (let index = 0; index < noOfWeeks; index++) {
        const NEW_START = (index + 1) * 7 * ONE_DAY_TIME + startNew;
        const NEW_END = (index + 1) * 7 * ONE_DAY_TIME + endNew;
        const eventAdd = {
          title: tittle,
          allDay: false,
          start: new Date(NEW_START).toString(),
          end: new Date(NEW_END).toString(),
          id: uuid(),
          extra: {
            employee: activeEmployee.name,
            employeeColor: activeEmployee.color,
          },
        };
        event.push(eventAdd);
      }
    }

    // Adding one event one day for every day during week
    if (checked && noOfWeeks === 0) {
      const i = selectedDate.getDay();
      for (let k = i + 1; k < 7; k++) {
        const NEW_START = (k - i) * ONE_DAY_TIME + startNew;
        const NEW_END = (k - i) * ONE_DAY_TIME + endNew;

        const eventAdd = {
          title: tittle,
          allDay: false,
          start: new Date(NEW_START).toString(),
          end: new Date(NEW_END).toString(),
          id: uuid(),
          extra: {
            employee: activeEmployee.name,
            employeeColor: activeEmployee.color,
          },
        };
        event.push(eventAdd);
      }
    }

    // Adding one event for every day during the next week for the next noOfWeeks weeks
    if (checked && noOfWeeks > 0) {
      let counter = 1;

      for (let weeklyIndex = 0; weeklyIndex < noOfWeeks; weeklyIndex++) {
        // this makes it sunday, loop adds one which makes it monday ; when the loop starts today event has been added from the top
        let i = weeklyIndex === 0 ? selectedDate.getDay() : 0;
        for (let k = i + 1; k < 7; k++) {
          const NEW_START = counter * ONE_DAY_TIME + startNew;
          const NEW_END = counter * ONE_DAY_TIME + endNew;

          const eventAdd = {
            title: tittle,
            allDay: false,
            start: new Date(NEW_START).toString(),
            end: new Date(NEW_END).toString(),
            id: uuid(),
            extra: {
              employee: activeEmployee.name,
              employeeColor: activeEmployee.color,
            },
          };
          event.push(eventAdd);
          counter++;
        } // end of inner loop

        counter = counter + 1;
      } // end of outer loop
    } //            end of if

    props.firebase.writeEventData(event);
  };

  return (
    <div
      style={{
        padding: "3rem",
        margin: "3rem",
        border: "1px solid #00BDFE",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        rowGap: "10px",
        textAlign: "left",
        minHeight: "400px",
        height: "auto",
        backgroundColor: "white",
      }}>
      <h1 style={{ textAlign: "center" }}> Add Events :) </h1>

      <h4>Choose an employee</h4>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px,1fr)",
          gap: "5px",
        }}>
        {emoployeesCards}
      </div>

      <br />

      {activeEmployee.name && (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(200px,1fr)",
            gap: "5px",
          }}>
          <h3>Chosen Employee:</h3>
          <p
            style={{
              backgroundColor: "lightgreen",
              textAlign: "center",
              fontWeight: "600",
              padding: "10px",
              borderRadius: "20px",
            }}>
            {activeEmployee.name}
          </p>
          <div style={{ margin: "auto" }}>
            <Button
              onClick={() => handleActiveEmployee({})}
              variant='contained'
              color='secondary'>
              CLEAR
            </Button>
          </div>
        </div>
      )}

      <h4>Title for the Appointment Slot</h4>

      <input
        style={{
          border: "none",
          padding: "0.5rem",
          borderBottom: "1px solid blue",
          color: "red",
          marginBottom: "1rem",
        }}
        value={tittle}
        onChange={(e) => handleTitleChange(e.target.value)}
      />

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateTimePicker
          value={selectedDate}
          disablePast
          onChange={handleDateChange}
          label='Click to enter Start Time'
          showTodayButton
        />
      </MuiPickersUtilsProvider>

      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateTimePicker
          value={selectedEndDate}
          disablePast
          onChange={handleEndDateChange}
          label='Click to enter End Time'
          showTodayButton
        />
      </MuiPickersUtilsProvider>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(130px,1fr)",
          gap: "25px",
        }}>
        {minutes.map((mins, index) => (
          <Button
            onClick={() => handleEndHelper(mins)}
            variant='contained'
            color={index % 2 === 0 ? "primary" : "secondary"}>
            {mins} minutes
          </Button>
        ))}
      </div>

      {checkIfDisableEveryDayInWeekCheckbox ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "1rem 0",
            rowGap: "25px",
          }}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={(e) => setChecked(e.target.checked)}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label='Every day in Week'
            />
          </FormGroup>
          <FormControl className={classes.formControl}>
            <InputLabel id='demo-simple-select-label'>
              For the next number of weeks
            </InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={noOfWeeks}
              onChange={(e) => setNoOfWeeks(e.target.value)}>
              {formControlButtons()}
            </Select>
          </FormControl>
        </div>
      ) : (
        <h3> Selecting particular days in week</h3>
      )}

      <FormGroup
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px,1fr)",
          gap: "5px",
        }}>
        {weekDaysSelector}
      </FormGroup>

      <Button onClick={addThisEvent} variant='contained' color='primary'>
        Add Event
      </Button>
    </div>
  );
}

export default withFirebase(EventsAddingForm);
