// firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
//   .then(function() {
//     // Existing and future Auth states are now persisted in the current
//     // session only. Closing the window would clear any existing state even
//     // if a user forgets to sign out.
//     // ...
//     // New sign-in will be persisted with session persistence.
//     return firebase.auth().signInWithEmailAndPassword(email, password);
//   })
//   .catch(function(error) {
//     // Handle Errors here.
//     var errorCode = error.code;
//     var errorMessage = error.message;
//   });

import React from 'react'
import SignIn from './Signin'
 


function Login (){
 
 

  

 
  return (
    <div style={{
      padding: '5rem',
      minHeight: '1000px'
    }} >
 
    <SignIn />
    </div>
 
  )

}

export default  Login 
