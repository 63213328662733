import React from 'react'

export default function EmployeeCard(props){

    const handleClick = () =>{
        props.handleActiveEmployee(props.employee)
    }
    const getBackgroundColor =() =>{
        return (props.activeEmployee.id === props.employee.id) ? 'yellowgreen' : 'white'
    }


    return(
        <div onClick={handleClick} style={{
            display: 'flex',
            flexDirection: 'row',
            border: '1px solid black',
            padding: '1rem',
            cursor: 'pointer',
            borderRadius: '10px',
            boxShadow: '0 0 10px grey',
            backgroundColor: `${getBackgroundColor()}`,

        }}>
            <div style={{
                height:'50px',
                width: '50px',
                borderRadius: '50px',
                backgroundColor: `${props.employee.color}`,
              }}>

            </div>

            <div style={{
                  height:'50px',
                  width: '80%',
                  margin: 'auto',
                  backgroundColor: `${props.employee.color}`,
                  borderRadius: '20px',
                  textAlign: 'center',
                  paddingTop:'10px',
              }}>
               { props.employee.name}
            </div>

        </div>    
    )
}
