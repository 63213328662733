 import React from 'react';
import Store from './app_state/store'
import { Provider } from 'react-redux'
import './App.css';
import { createBrowserHistory } from "history";
import { BrowserRouter, Route, Switch } from "react-router-dom";
 import  FirebaseContext  from './app_state/FirebaseContext';
import Firebase from './app_state/firebase'
import Login from './Components/Login'
import PrivateDashboard from './Components/PrivateDashboard'
import BookedAppointments from './Components/BookedAppointments'
import Header from "./Components/Header";
import Footer from "./Components/Footer"
import AddBackendAssets from './Components/AddBackendAssets'

const history = createBrowserHistory({forceRefresh:true});

function App() {
 
  return (
    <BrowserRouter history={history}>
    <Provider store={Store} >
    <FirebaseContext.Provider value={new Firebase()}>
      <div className="App">
        <div className='header'>
          <Header  />

        </div>
        <div className='main'>

       
          <Switch> 
            <Route exact path="/addbackend" component={AddBackendAssets} />
            <Route exact path={['/bookedappointments', '/bookedappointments/:id']} component={BookedAppointments} />
            <Route exact path="/login" component={Login} />
            <Route path="/" component={PrivateDashboard} />
          </Switch>

          </div>
        <div className='footer'>
          <Footer />
        </div>
      </div>
    </FirebaseContext.Provider>,
    </Provider>
    </BrowserRouter>

    
  );
}

export default App;








/*

echo "# orchid11" >> README.md
git init
git add README.md
git commit -m "first commit"
git branch -M main
git remote add origin https://github.com/paramveer73/orchid11.git
git push -u origin main

Here is your case number 26480108.

…or push an existing repository from the command line
git remote add origin https://github.com/paramveer73/orchid11.git
git branch -M main
git push -u origin main

    <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDtYHlNmUEKMxEf38a0DBzrA4_lxslicJk&libraries=geometry,drawing,places"

test email = kaimparam@gmail.com
test password = passwordsS@

*/