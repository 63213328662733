import React, {useEffect} from 'react'
import EventsAddingForm from './EventsAddingForm'
import Calender from './Calender'
import { withRouter   } from 'react-router-dom'
import { withFirebase } from "../app_state/FirebaseContext";
import {compose} from 'recompose'


 function PrivateDashboard(props) {
    useEffect(() => {
        var listener = props.firebase.auth.onAuthStateChanged(authUser => {
         if(authUser){
            }else {
                props.history.push('/login')
            }
        })
        return () => {
          listener()
        }
      }, [props.firebase, props.history])
 
    return (
        <div style={{minHeight: '500px'}} >
           <EventsAddingForm />
           <Calender />
        </div>
    )
}

export default compose(withFirebase,withRouter)(PrivateDashboard)






          
     


