import React, {useEffect} from 'react'
import {withFirebase} from '../app_state/FirebaseContext'
import Masterview from './Masterview'
import DetailView from './DetailView'
import { useParams} from "react-router-dom";
 
 function BookedAppointments(props) {
     const {id} = useParams()

    const [bookedAppointments, setBookedAppointments] = React.useState({})


    const handleBookedAppointmentDelete = (userdata) => {
       return props.firebase.deleteBookedAppointment(userdata)
    }


    useEffect(() => {
        props.firebase.bookedAppointments()
        .on('value', snapshot => {
            console.log('coming from booked appointment');
            const val = snapshot.val()
          if (val) {
           const tempState = {}
           Object.keys(val).map((monthlykey)=>{                 // looping through months which contains data about monthly booked appointments
              const thisMonthAppointments = val[monthlykey]     // contains an object of monthlykeys
              var tempArrayofThisMonthlyBookedAppointments =  Object.keys(thisMonthAppointments).map((keys)=>{
                  var individualBookedAppointmentobject = thisMonthAppointments[keys]
                  var temp = {}
 
                   temp["userdata"] = individualBookedAppointmentobject["userdata"]
                   temp["userdata"]["epoch"] =  new Date(individualBookedAppointmentobject["userdata"].start).getTime()
                  delete individualBookedAppointmentobject["userdata"]
                
                  temp.services = Object.values(individualBookedAppointmentobject)

                  return temp
               })
               tempState[monthlykey] = tempArrayofThisMonthlyBookedAppointments
           })
           // setter method is hanfleeventstodisplay and object formatting method is events
            setBookedAppointments(tempState)
           } 
    
         })
          return () => {
              props.firebase.freeAppointments().off()
            }
      }, [])

    useEffect( () => {
        var listener = props.firebase.auth.onAuthStateChanged(authUser => {
             if(authUser){
            }else {
                props.history.push('/login')
            }
        })
        return () => {
          listener()
        }
      }, [] )




if(id){
    return (
        <div>
            <DetailView handleBookedAppointmentDelete={handleBookedAppointmentDelete} id={id} appointments={bookedAppointments[id]} />
        </div>
    )
}
    return (
        <div>
              <Masterview bookedAppointments={bookedAppointments} />
        </div>
    )
}

export default withFirebase(BookedAppointments)