import React from 'react'
import {withRouter} from 'react-router-dom'

function DashboardView(props) {
    return (
        <div style={{
            textAlign: 'left',
            padding: '1rem',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flex: '0 1 500px',
            alignItems: 'center',
            fontSize: '1.2rem',
            borderRadius: '10px',
            boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'
        }}>
            <div>
                <div>
                    <h3>
                        {props.title}
                    </h3>
                </div>

                <div >
                    <span style={{color: '#1f2f98'}}>
                         Total Booked Appointments :  
                    </span>
                    <span style={{color: '#fe676e'}}>
                        {` ${props.length}`}
                     </span>
                </div>
            </div>
            <div>
                <button onClick={()=> props.history.push(`/bookedappointments/${props.yearmonth}`)} style={{
                    backgroundColor: 'white',
                    color: 'black',
                    border: '2px solid #4CAF50',
                    padding: '16px 32px',
                    cursor: 'pointer',
                    borderRadius: '10px' 
                }}>
                    DETAILS
                </button>
            </div>
            
            
        </div>
    )
}

export default withRouter(DashboardView)