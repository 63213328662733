import React from 'react'
import { Link } from "react-router-dom";
import {withFirebase} from '../app_state/FirebaseContext'

 function Header(props) {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            padding: '1rem',
            backgroundColor: '#266bcd',
            color: 'white',
            fontWeight: 'bold',
            flexWrap: 'wrap',
            gap: '25px',
            boxShadow: 'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
        }} >
            <h3 style={{ flex: '0 1 200px' }}>
                ORCHID 11 MED SPA
            </h3>
            <Link style={{ textDecoration: 'none', color: 'white',flex: '0 1 200px'}} to='/'> 
                Manage Appointment 
            </Link> 
            <Link style={{ textDecoration: 'none', color: 'white',flex: '1 1 200px'}} to='/bookedappointments'> 
               Booked Appointment 
            </Link> 

            <Link style={{ textDecoration: 'none', color: 'white',flex: '0 1 100px'}} onClick={()=> props.firebase.doSignOut()} to='/login'>
                Logout
            </Link>
            
        </div>
    )
}

export default withFirebase(Header)
