import React , {useEffect} from 'react'
import {withFirebase} from '../app_state/FirebaseContext'

function AddBackendAssets(props) {
    const detailFileInput = React.createRef();
     const [coverImgLink, setCoverImgLink] = React.useState(false)   

     useEffect(() => {
        var listener = props.firebase.auth.onAuthStateChanged(authUser => {
         if(authUser){
            }else {
                props.history.push('/login')
            }
        })
        return () => {
          listener()
        }
      }, [props.firebase, props.history])
 
    const handleCoverFileSelect = (e) => {
        setCoverImgLink('uploading . . .')

        e.preventDefault();
        const file = detailFileInput.current.files[0]
        const name = file.name
        console.log(file.name)
        const location = `images/${name}`
        const tempref = props.firebase.storageBucket.ref(location)
         tempref.put(file).then((snapshot)=>{
             return snapshot.ref.getDownloadURL()
         }).then((downloadLink)=>{
             setCoverImgLink(downloadLink)
          }).catch((err)=>{
           window.alert(err)
         })
     


    }



    return (
        <div>
             <h2 style={{textAlign: 'left'}}>
                    Add Asset Pictures
                </h2>
                <input onChange={handleCoverFileSelect} style={{
                    padding: '1rem 0.5rem',
                    cursor: 'pointer',
                    
                }} ref={detailFileInput} type='file' />

               <p>
                   {coverImgLink}
               </p>
            
        </div>
    )
}
export default withFirebase(AddBackendAssets)
