import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import MuiAlert from '@material-ui/lab/Alert';
import { withRouter } from 'react-router-dom';
import { withFirebase } from "../app_state/FirebaseContext";
import { compose } from 'recompose';
import Snackbar from '@material-ui/core/Snackbar';



const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

function SignIn(props) {
    const [values, setValues] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [isChecked, setIsChecked] = React.useState(false)
    const [error, setError] = React.useState(false)

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setError(false);
      };

    const handleWeekDayChange = (event) => {
        setIsChecked( event.target.checked );
    };

    function emailIsValid (email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }
      

    const handleSignIn = () => {
        props.firebase.doSignInWithEmailAndPassword(email,values).then(
            (ans)=>{
                props.history.push('/private');
            }
        ).catch(err=>{
            setError(err.message)
        })        
    }


  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <div className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            value={email}
            onChange={(e)=> setEmail(e.target.value)}
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            color={emailIsValid(email)?"primary":"secondary"}

          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            onChange={(e)=> setValues(e.target.value)}
            value={values}
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <FormControlLabel
            control={<Checkbox 
                color="primary" 
                onChange={handleWeekDayChange} 

                checked={isChecked} 
            />}
            label="Keep Me Logged In"
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleSignIn}
            className={classes.submit}
          >
            Sign In
          </Button>
         
        </div>
        {
              error &&
              <Snackbar        
              open={error} autoHideDuration={4000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="error">
               {error}
              </Alert>
            </Snackbar>
              
            }
      </div>
     
    </Container>
  );
}


export default compose(
    withRouter,
    withFirebase
) (SignIn)